import React from "react";
import css from './card.module.scss';

const Card = props => {
  const { image, imageAlt, title, text } = props;

  return (
    <div className={css.wrapper}>
      <div className={css.imageWrapper}>
        <img src={image} alt={imageAlt} />
      </div>
      { title &&
        <div className={css.titleWrapper}>
          {title}
        </div>
      }
      <div className={css.textWrapper}>
        {text}
      </div>
    </div>
  )
};

export default Card;
