import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import Footer from "../components/shared/Footer/Footer";
import About from "../components/pages/AboutPage/partials/about/About";
import {getRoutes} from '../components/shared/routes';
import AdditionalInformation
    from "../components/shared/additionalInformation/additionalInformation";
import Mission from "../components/sections/Mission/Mission";
import Vision from "../components/sections/Vision/Vision";
import Values from "../components/sections/Values/Values";
import PageTitle from "../components/shared/PageTitle/PageTitle";


const AboutPage = () => {
    const pageTitle = getRoutes().About.pageTitle;

    return (
        <Layout>
            <SEO title={pageTitle}/>

            <PageTitle>{pageTitle}</PageTitle>

            <About/>

            <Mission/>

            <Vision/>

            <Values/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default AboutPage;
