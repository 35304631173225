import React from "react";
import css from './about.module.scss';
import Cards from "../../../../shared/cards/Cards";
import PolandImage from "../../../../../images/About/about/about_poland.svg";
import TeamImage from "../../../../../images/About/about/about_team.svg";
import AiImage from "../../../../../images/About/about/about_ai.svg";
import MainButton, {mainButtonFill} from "../../../../shared/MainButton/MainButton";
import logo from "../../../../../images/logos/deeplai-company-logo-white.svg";
import playVideo from "../../../../../images/About/about/play_button_o.svg";

const About = () => {
  const cards = [{
      image: PolandImage,
      text: "We are a team of Polish innovators fascinated by inventing game-changing DeepTech solutions for climate and environmental protection.",
      imageAlt: "polish flag"
    
    }, {
      image: TeamImage,
      text: <>A team of continuously learning enthusiasts and engineers, encouraging and supporting each other.<br/><br/>Providing human friendly technologies for successful and just transformation.</>,
      imageAlt: "deeplai team"

    }, {
      image: AiImage,
      text: "We aim to build advanced software solutions powered by AI/ML, in combination with invented devices focused on digitalisation and the automation of processes.",
      imageAlt: "deeplai ai"

    }];

  return (
    <div className={css.wrapper}>
      <div className={css.cards}>
        <Cards
          cards={cards}
        />
      <div className={css.logo}><img src={logo} height={'35px'} alt={""}/></div>
      <div className={css.text}>playlist</div>
        <div className={css.videoCardButtonsWrapper}>
             <MainButton
                href={'https://www.youtube.com/watch?v=k4znCcWigCo&list=PLrYEunNGWNN2M40MMafcLO9MsKpWgvXLp'}
                label={<div className={css.playVideoLabel}><div>Play videos</div> <img src={playVideo} alt=""/></div>}
                fill={mainButtonFill.gradient}
            />
        </div>
      </div>
    </div>
  )
};

export default About;
