import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './vision.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_vision"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 560, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Vision = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'Vision';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'48%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'vision.png', 'vision')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'52%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText> The</AppGreyText>
                    innovative ability <AppGreyText> to </AppGreyText> automate processes opens massive
                    opportunities across various market verticals.
                    <br/><br/>
                    <AppGreyText> The</AppGreyText>
                    overarching goal <AppGreyText> is to </AppGreyText>drive digital transformation in
                    value-creating ways.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default Vision;
