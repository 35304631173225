import React from "react";
import css from './cards.module.scss';
import useBreakpoint from "../../hooks/useBreakpoint";
import _ from 'lodash';
import Card from "./Card";
import {viewports} from "../../componentsUtils";

const Cards = props => {
  const { cards = [] } = props;

  const breakpoint = useBreakpoint();

  const getChunk = () => {
    switch (breakpoint.viewport) {
      case viewports.phoneViewport:
        return 1;
      case viewports.tabletViewport:
        return 2;
      default:
        return 3;
    }
  };

  const renderCard = (card, cardIndex, rowIndex) => {
    const { image, imageAlt, title, text } = card;
    return (
      <Card
        key={`card_${rowIndex}_${cardIndex}`}
        image={image}
        imageAlt={imageAlt}
        title={title}
        text={text}
      />
    );
  };

  const renderRow = (row, rowIndex) => {
    const rowCards = row.map((card, cardIndex) => renderCard(card, cardIndex, rowIndex));
    return (
      <div className={css.row} key={`row_${rowIndex}`}>
        {rowCards}
      </div>
    )
  };

  const renderRows = () => {
    const rows = _.chunk(cards, getChunk());
    return rows.map((row, index) => renderRow(row, index));
  };

  return (
    <div className={css.wrapper}>
      {renderRows()}
    </div>
  )
};

export default Cards;
