import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './values.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_values"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 560, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Values = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'Values';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'34%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'values.png', 'values')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'56%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText>Our team</AppGreyText>
                    encourages <AppGreyText>and </AppGreyText> supports each other.
                    <br/><br/>
                    <AppGreyText>Our daily commitment, engagement and passion drives us to</AppGreyText>
                    make the creatives world a part of the today’s social responsibility
                    as <AppGreyText> the</AppGreyText> technology influencer.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default Values;
