import {graphql, useStaticQuery} from 'gatsby';
import React from "react";
import css from './mission.module.scss';
import {getImagesFluid, renderImageByFileName} from '../../componentsUtils';
// import PhoneFrameVideo from "../../shared/PhoneFrameVideo/PhoneFrameVideo";
import Text from "../../shared/Text/Text";
import {SectionDesktopTitle, SectionMobileTitle} from "../../shared/Section/SectionTitle";
import SectionImageVideoWrapper from "../../shared/Section/SectionImageVideoWrapper";
import SectionTextWrapper from "../../shared/Section/SectionTextWrapper";
import AppGreyText from "../../shared/AppGreyText/AppGreyText";

const query = graphql`
{
  fluid: allFile(filter: {sourceInstanceName: {eq: "images_sections_mission"}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 560, quality: 70) {
          ...GatsbyImageSharpFluid,
          originalName
        }
      }
    }
  }
}`;

const Mission = () => {
    const imagesFluid = getImagesFluid(useStaticQuery(query));

    const title = 'Mission';

    return (

        <div className={css.wrapper}>
            <SectionMobileTitle>{title}</SectionMobileTitle>
            <SectionImageVideoWrapper desktopWidth={'34%'}>
                <div className={css.imageVideoWrapper}>
                    <div className={css.imageWrapper}>
                        {renderImageByFileName(imagesFluid, 'mission.png', 'mission')}
                    </div>
                    {/*<PhoneFrameVideo
                        videoSrcURL={MarkingVideo}
                        placeholderImage={'Marking_Video.jpg'}
                        imagesFluid={imagesFluid}
                    />*/}
                </div>
            </SectionImageVideoWrapper>
            <SectionTextWrapper desktopWidth={'56%'}>
                <SectionDesktopTitle>{title}</SectionDesktopTitle>
                <Text>
                    <AppGreyText> We are </AppGreyText> helping our customers
                    <AppGreyText>to make the digital transformation move and</AppGreyText>
                    automate processes <AppGreyText> for</AppGreyText> making better and faster
                    decisions,
                    <AppGreyText> for</AppGreyText>
                    impacting market competitiveness and financial standing,
                    <AppGreyText>for</AppGreyText>
                    protecting their brand value,
                    <AppGreyText>for</AppGreyText>
                    being a part of the green environment.
                </Text>
            </SectionTextWrapper>
        </div>
    )
};

export default Mission;
